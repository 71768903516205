/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Pig } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  femalePigs: Pig[]; //
  malePigs: Pig[]; //
  meatPigs: Pig[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  femalePigs: [],
  malePigs: [],
  meatPigs: [],
  query: {
    page: 1,
    limit: 3,
  },
  isLoading: false,
};

/**
 *  Slice manager herd
 **/

export const pigSlice: any = createSlice({
  name: "pigSlice",
  initialState: initialState,
  reducers: {
    getFemalePigs: (state, action) => {
      state.isLoading = true;
    },
    getMalePigs: (state, action) => {
      state.isLoading = true;
    },

    getMeatPigs: (state, action) => {
      state.isLoading = true;
    },

    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
  },
});
export const { getFemalePigs, getMalePigs, setQuery, getMeatPigs } =
  pigSlice.actions;
export default pigSlice.reducer;
