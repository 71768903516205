/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Herd } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  herds: Herd[]; //
  barns: Herd[]; //
  pigByUserId: any;

  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  herds: [],
  barns: [],
  pigByUserId: [],
  query: {
    page: 1,
    limit: 10,
  },
  isLoading: false,
};

/**
 *  Slice manager herd
 **/

export const herdSlice: any = createSlice({
  name: "herdSlice",
  initialState: initialState,
  reducers: {
    getHerds: (state, action) => {
      state.isLoading = true;
    },
    getHerdsSuccess: (state, action) => {
      state.herds = action.payload;
      state.isLoading = false;
    },
    getBarnByHerd: (state, action) => {
      state.barns = action.payload;
      state.isLoading = false;
    },
    getPigByUserId: (state, action) => {
      state.pigByUserId = action.payload;
      state.isLoading = false;
    },

    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createHerd: (state, action) => {
      state.isLoading = true;
    },
    updateHerd: (state, action) => {
      state.isLoading = true;
    },
    deleteHerd: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  getHerds,
  getHerdsSuccess,
  setQuery,
  getBarnByHerd,
  createHerd,
  updateHerd,
  getPigByUserId,
  deleteHerd,
} = herdSlice.actions;
export default herdSlice.reducer;
