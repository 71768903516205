import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, POST_FILE, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* importPig(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.pigs}/guest/import${params ?? ""}?${query ?? ""}`;
  try {
    const res = yield call(POST_FILE, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* importHistoryPig(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.pigHistorys}/guest/import${params ?? ""}?${
    query ?? ""
  }`;
  try {
    const res = yield call(POST_FILE, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* importSaga() {
  yield takeLatest("importSlice/importPig", importPig);
  yield takeLatest("importSlice/importHistoryPig", importHistoryPig);
}
