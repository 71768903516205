import { getAwardsSuccess } from "features/awardSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getAwards(data: ActionType): any {
  const { callback, body, query } = data.payload;
  const url = `${ServiceURL.award}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getAwardsSuccess(res));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* awardSaga() {
  yield takeLatest("awardSlice/getAwards", getAwards);
}
