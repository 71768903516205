/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Select,
  Table,
  Typography,
  notification,
  Switch,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { Input, Space } from "antd";
import { ModalInfoType, PayloadType, SelectOptionType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";

import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getPromotionDetails,
  setQuery,
  updatePromotionDetails,
} from "features/promotionDetailSlice";
import queryString from "query-string";
import CUPromotionDetail from "pages/PromotionDetail/ListPromotion/CUPromotionDetail";
const { Title } = Typography;
interface DataType {
  usedAmount: number;
  status: boolean;
  code: string;
  promotionId: string;
  amount: number;
  servicePackId: string;
  startDate: string;
  endDate: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function ListPromotionDetail() {
  const dispatch = useDispatch();
  const { query, promotionDetails } = useSelector(
    (state: any) => state.promotionDetailReducer
  );
  const [statusSwitch, setStatusSwitch] = useState(true);

  //Lấy danh sách khuyến mãi chi tiết
  function getListDetailPromotion() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "promotionId, servicePackId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  }
  useEffect(() => {
    getListDetailPromotion();
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Mã KM",
      dataIndex: "code",
      key: "code",
      width: 200,
    },
    {
      title: "Chương trình khuyến mãi",
      dataIndex: ["promotionId", "name"],
      key: "promotionId.name",
      width: 400,
    },
    {
      title: "DV áp dụng",
      dataIndex: ["servicePackId", "name"],
      key: "servicePackId.name",
      align: "center",
      width: 200,
      render: (value, record) => {
        return (
          <div style={{ textAlign: "right" }}>
            {`${_.isNull(value) ? "-" : value}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            )}
          </div>
        );
      },
    },
    {
      title: "Tổng SL",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      align: "center",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>
            {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </div>
        );
      },
    },

    {
      title: "SL đã dùng",
      dataIndex: "contractIds",
      key: "contractIds",
      width: 200,
      align: "center",
      render: (value) => {
        return <div style={{ textAlign: "right" }}>{`${value}`.length}</div>;
      },
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 300,
      align: "center",
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "endDate",
      key: "endDate",
      width: 300,
      align: "center",
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },

    {
      title: "Trạng thái KH",
      dataIndex: "status",
      key: "status",
      width: 200,
      align: "center",
      render: (_, record: any, val: any) => {
        return (
          <Space direction="vertical">
            {record.status === "active" ? (
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={() => {
                  const idUpdate = record?.id ?? "";
                  if (idUpdate) {
                    const payload: PayloadType = {
                      params: idUpdate,
                      body: {
                        code: record?.code,
                        servicePackId: record?.servicePackId,
                        amount: record?.amount,
                        promotionId: record?.promotionId.id,
                        startDate: record?.startDate,
                        endDate: record?.endDate,
                        status: record?.status === "active" ? "lock" : "active",
                      },
                      callback: {
                        success: (values) => {
                          notification.success({
                            message:
                              "Thay đổi trạng thái kích hoạt thành công!",
                          });
                          getListDetailPromotion();
                        },
                        failed(errorMessage) {
                          notification.error({
                            message: `Thay đối trạng thái kích hoạt không thành công!`,
                            description: errorMessage,
                          });
                        },
                      },
                    };
                    dispatch(updatePromotionDetails(payload));
                  }
                }}
                style={{
                  width: 65,
                }}
                defaultChecked={true}
              />
            ) : (
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={() => {
                  const idUpdate = record?.id ?? "";
                  if (idUpdate) {
                    const payload: PayloadType = {
                      params: idUpdate,
                      body: {
                        code: record?.code,
                        servicePackId: record?.servicePackId,
                        amount: record?.amount,
                        promotionId: record?.promotionId.id,
                        startDate: record?.startDate,
                        endDate: record?.endDate,
                        status: record?.status === "active" ? "lock" : "active",
                      },
                      callback: {
                        success: (values) => {
                          notification.success({
                            message:
                              "Thay đổi trạng thái kích hoạt thành công!",
                          });
                          getListDetailPromotion();
                        },
                        failed(errorMessage) {
                          notification.error({
                            message: `Thay đối trạng thái kích hoạt không thành công!`,
                            description: errorMessage,
                          });
                        },
                      },
                    };
                    dispatch(updatePromotionDetails(payload));
                  }
                }}
                style={{
                  width: 65,
                }}
                defaultChecked={false}
              />
            )}
          </Space>
        );
      },
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 300,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa chi tiết khuyến mãi ",
                  content: (
                    <CUPromotionDetail
                      idUpdate={record?.id ?? false}
                      record={record}
                    />
                  ),
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới chi tiết khuyến mãi",
                content: <CUPromotionDetail idUpdate={undefined} />,
                size: 600,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(promotionDetails, "results", [])}
        pagination={{
          total: _.get(promotionDetails, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListPromotionDetail;
