import {
  getSubscriberSuccess,
  getSubscribersSuccess,
} from "features/subscriberSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
const _ = require("lodash");
export function* getSubscribers(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.subscribers}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getSubscribersSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* getSubscriber(data: ActionType): any {
  const { callback, body, query, params } = data.payload;
  const url = `${ServiceURL.subscribers}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getSubscriberSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export function* createSubscriber(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.subscribers;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* setPassByAdmin(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.setpass;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* updateSubscriber(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.subscribers}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getSubscriberSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* deleteSubscriber(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.subscribers}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success();
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* subscriberSaga() {
  yield takeLatest("subscriberSlice/getSubscribers", getSubscribers);
  yield takeLatest("subscriberSlice/getSubscriber", getSubscriber);
  yield takeLatest("subscriberSlice/updateSubscriber", updateSubscriber);
  yield takeLatest("subscriberSlice/createSubscriber", createSubscriber);
  yield takeLatest("subscriberSlice/deleteSubscriber", deleteSubscriber);
  yield takeLatest("subscriberSlice/setPassByAdmin", setPassByAdmin);
}
