/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";

import {
  Button,
  Popconfirm,
  Select,
  Table,
  Typography,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { Input, Space } from "antd";
import { ModalInfoType, PayloadType, SelectOptionType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import { useSelector } from "react-redux";
import _, { sortBy } from "lodash";
import {
  getServicePackages,
  deleteServicePackages,
  setQuery,
} from "features/servicePackageSlice";
import queryString from "query-string";
import CUService from "./CUService";
const { Title } = Typography;
interface DataType {
  phone: string;
  name: string;
  nameForPig: string;
  farmName: string;
  farmTypeId: string;
  farmScaleId: string;
  email: string;
  personInChargeId: string;
  address: string;
  provinceId: string;
  servicePackageId: string;
  serviceTimeId: string;
}
const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function ListService() {
  const dispatch = useDispatch();
  const { query, servicePackages } = useSelector(
    (state: any) => state.servicePackageReducer
  );

  //Lấy danh sách gói dịch vụ
  function handleGetServicePacks() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  }
  useEffect(() => {
    handleGetServicePacks();
  }, [query]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Tên gói thuê bao",
      dataIndex: "name",
      key: "name",
    },
    {
      title: () => {
        return <div>Quy mô tổng số nái</div>;
      },
      dataIndex: "farmScaleTTNId",
      key: "farmScaleTTNId",
      width: 200,
      align: "center",
      render: (value) => {
        return <div style={{ textAlign: "right" }}>{value?.name}</div>;
      },
    },
    {
      title: () => {
        return <div>Quy mô tổng số thịt </div>;
      },

      dataIndex: "farmScaleTTTId",
      key: "farmScaleTTTId",
      width: 240,
      align: "center",
      render: (value) => {
        return <div style={{ textAlign: "right" }}>{value?.name}</div>;
      },
    },
    {
      title: "Thời gian(tháng)",
      dataIndex: "time",
      key: "time",
      width: 200,
      align: "center",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>
            {` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </div>
        );
      },
    },
    {
      title: "Giá tiền (vnđ)",
      dataIndex: "price",
      key: "price",
      width: 200,
      align: "center",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>
            {` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </div>
        );
      },
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa gói dịch vụ",
                  content: (
                    <CUService idUpdate={record?.id ?? false} record={record} />
                  ),
                  size: 600,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id;
                const payload: PayloadType = {
                  params: id,
                  callback: {
                    success(values) {
                      notification.success({
                        message: "Xoá nhóm gói dịch vụ thành công!",
                      });
                      handleGetServicePacks();
                    },
                    failed(errorMessage) {
                      notification.error({
                        message: "Xoá nhóm gói dịch vụ thất bại!",
                        description: errorMessage,
                      });
                    },
                  },
                };
                dispatch(deleteServicePackages(payload));
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới gói thuê bao",
                content: <CUService idUpdate={undefined} />,
                size: 600,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(servicePackages, "results", [])}
        pagination={{
          total: _.get(servicePackages, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListService;
