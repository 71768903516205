import { all, fork } from "redux-saga/effects";
import { accountSaga } from "sagas/accountSaga";
import userSaga from "./userSaga";
import roleSaga from "./roleSaga";
import provinceSaga from "./provinceSaga";
import districtSaga from "./districtSaga";
import awardSaga from "./awardSaga";
//HDH
//DTA
import typeDataSharedSaga from "sagas/typeDataSharedSaga";
import sharedDataSaga from "sagas/sharedDataSaga";
import configSaga from "sagas/configSaga";
import feedbackSaga from "sagas/feedbackSaga";
import subscriberSaga from "sagas/subscriberSaga";
import servicePackageSaga from "./servicePackageSaga";
import promotionSaga from "./promotionSaga";
import parameterSaga from "./parameterSaga";
import typeGroupSaga from "./typeGroupSaga";
import procedureSaga from "./procedureSaga";
import herdTypeSaga from "./herdTypeSaga";
import promotionDetailSaga from "./promotionDetailSaga";
import productTempSaga from "./productTempSaga";
import productSaga from "./productSaga";
import herdSaga from "./herdSaga";
import statisticalSaga from "./statisticalSaga";
import pigTypeSaga from "./pigTypeSaga";
import pigSaga from "./pigSaga";
import contractSaga from "./contractSaga";
import importSaga from "./importSaga";
function* rootSaga() {
  yield all([fork(accountSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(provinceSaga)]);
  yield all([fork(districtSaga)]);
  yield all([fork(awardSaga)]);
  yield all([fork(typeDataSharedSaga)]);
  yield all([fork(sharedDataSaga)]);
  yield all([fork(configSaga)]);
  yield all([fork(feedbackSaga)]);
  yield all([fork(subscriberSaga)]);
  yield all([fork(servicePackageSaga)]);
  yield all([fork(promotionSaga)]);
  yield all([fork(parameterSaga)]);
  yield all([fork(typeGroupSaga)]);
  yield all([fork(procedureSaga)]);
  yield all([fork(herdTypeSaga)]);
  yield all([fork(promotionDetailSaga)]);
  yield all([fork(productSaga)]);
  yield all([fork(productTempSaga)]);
  yield all([fork(herdSaga)]);
  yield all([fork(statisticalSaga)]);
  yield all([fork(pigTypeSaga)]);
  yield all([fork(pigSaga)]);
  yield all([fork(importSaga)]);
  yield all([fork(contractSaga)]);
}
export default rootSaga;
