import { useEffect, useState } from "react";
const { Search } = Input;
import {
  Popconfirm,
  Space,
  Typography,
  Col,
  Row,
  notification,
  Input,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, ListDataSharedType } from "type";
import Table, { ColumnsType } from "antd/lib/table";
import CUTypeDataShared from "pages/TypeDataShared/CUTypeDataShared";
import queryString from "query-string";
import {
  deleteTypeGroup,
  getTypeGroups,
  setQuery,
} from "features/typeDataSharedSlice";
import { getSharedDatas } from "features/sharedDataSlice";
import _ from "lodash";
const { Title } = Typography;
interface DataType {
  code: string;
  name: string;
  note: string;
}

function TypeDataShared() {
  const dispatch = useDispatch();
  const [rowUpdate, setRowUpdate] = useState<DataType | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [listSharedData, setListSharedData] = useState<ListDataSharedType[]>(
    []
  );
  const { query, typeGroups } = useSelector(
    (state: any) => state.typeDataSharedReducer
  );

  const columns: ColumnsType<DataType> = [
    {
      title: "Mã số",
      dataIndex: "code",
      key: "code",
      align: "left",
      render(value) {
        return value;
      },
    },
    {
      title: "Tên nhóm thể loại",
      dataIndex: "name",
      key: "name",
      align: "left",
    },

    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "left",
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space>
            <a
              onClick={() => {
                setRowUpdate(record);
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (id) {
                  if (listSharedData.indexOf(id) == -1) {
                    const payload: PayloadType = {
                      params: id,
                      callback: {
                        success() {
                          notification.success({
                            message: "Xoá nhóm thể loại thành công!",
                          });
                          getTypeDataShared();
                        },
                        failed(errorMessage) {
                          notification.error({
                            message: "Xoá nhóm thể loại thất bại!",
                            description: errorMessage,
                          });
                        },
                      },
                    };
                    dispatch(deleteTypeGroup(payload));
                  } else {
                    notification.warning({
                      message:
                        "Nhóm thể loại này đang được sử dụng. Vui lòng kiểm tra lại !",
                    });
                  }
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  // get data type shared
  const getTypeDataShared = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
      }),
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Lỗi khi lấy thông tin nhóm thể loại",
            description: errorMessage,
          });
          return;
        },
      },
    };

    dispatch(getTypeGroups(payload));
  };

  //get list data shared to check deleted
  const getListSharedData = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            const uniquetypeGroupIds = Array.from(
              new Set(results.map((item) => item.typeGroupId))
            );
            setListSharedData(uniquetypeGroupIds);
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSharedDatas(payload));
  };

  useEffect(() => {
    getListSharedData();
  }, []);

  useEffect(() => {
    getTypeDataShared();
  }, [query]);

  useEffect(() => {
    dispatch(
      setQuery({
        page: 1,
        limit: 10,
      })
    );
  }, []);

  const handleSearch = () => {
    if (searchText === "") {
      dispatch(
        setQuery({
          page: "1",
          limit: 10,
        })
      );
    } else {
      dispatch(
        setQuery({
          ...query,
          name: searchText,
          page: 1,
        })
      );
    }
  };

  return (
    <Content className="custom-layout-content">
      <Row
        style={{
          height: "70px",
          marginBottom: "16px",
          lineHeight: "70px",
          padding: "16px 24px",
        }}
        className="background-content-layout"
      >
        <Col span={24}>
          <div className="space-between">
            <Title
              level={4}
              style={{
                marginBottom: "0px",
                height: "40px",
                lineHeight: "40px",
              }}
            >
              Danh mục nhóm/thể loại
            </Title>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <div
            className="background-content-layout"
            style={{
              padding: "24px",
              marginRight: "8px",
            }}
          >
            <>
              <div className="space-between">
                <Title
                  level={4}
                  style={{
                    marginBottom: "20px",
                    height: "40px",
                    lineHeight: "40px",
                  }}
                >
                  {Object.keys(rowUpdate ?? {}).length === 0
                    ? "Thêm mới"
                    : "Cập nhật"}
                </Title>
              </div>
              <CUTypeDataShared
                setRowUpdate={setRowUpdate}
                getTypeDataShared={getTypeDataShared}
                rowUpdate={rowUpdate}
              />
            </>
          </div>
        </Col>
        <Col span={18}>
          <div
            className=" background-content-layout"
            style={{ padding: "24px 16px", marginLeft: "8px" }}
          >
            <div
              className="space-between"
              style={{
                marginBottom: "20px",
                height: "40px",
                lineHeight: "40px",
                paddingLeft: "10px",
              }}
            >
              <Title level={5}>Danh sách</Title>
              <Search
                placeholder="Tìm theo tên nhóm thể loại"
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={handleSearch}
                style={{ width: 252 }}
              />
            </div>

            <Table
              style={{ marginLeft: "8px" }}
              rowKey="id"
              className=""
              columns={columns}
              dataSource={_.get(typeGroups, "results", [])}
              pagination={{
                current: _.get(typeGroups, "page", 1),
                pageSize: _.get(typeGroups, "limit", 1),
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                total: _.get(typeGroups, "totalResults", 1),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
                onChange(page, pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: page,
                      limit: pageSize,
                    })
                  );
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default TypeDataShared;
