/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { AdminDataType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  account: AdminDataType | null;
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  account: null,
  isLoading: false,
};

/**
 *  Slice manager Login/Logout/Change Password
 **/

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: initialState,
  reducers: {
    accountLogin: (state, action) => {
      state.isLoading = true;
    },
    accountLoginSuccess: (state, action) => {
      state.account = action.payload;
      state.isLoading = false;
    },
    changePassword: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const { accountLogin, accountLoginSuccess, changePassword } =
  accountSlice.actions;
export default accountSlice.reducer;
