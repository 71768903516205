/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Pig } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  pigs: Pig[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  pigs: [],
  query: {
    page: 1,
    limit: 10,
  },
  isLoading: false,
};

/**
 *  Slice manager herd type
 **/

export const pigTypeSlice: any = createSlice({
  name: "pigTypeSlice",
  initialState: initialState,
  reducers: {
    getPigs: (state, action) => {
      state.isLoading = true;
    },
    getPigsSuccess: (state, action) => {
      state.pigs = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    getPigByBarn: (state, action) => {
      state.pigs = action.payload;
      state.isLoading = false;
    },
    createPigs: (state, action) => {
      state.isLoading = true;
    },
    updatePigs: (state, action) => {
      state.isLoading = true;
    },
    deletePigs: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createPigs,
  updatePigs,
  deletePigs,
  getPigs,
  getPigByBarn,
  getPigsSuccess,
  setQuery,
} = pigTypeSlice.actions;
export default pigTypeSlice.reducer;
