/**
 * Giao diện hiển thị danh sách hợp đồng khách hàng
 */
import { useEffect, useState } from "react";
import moment from "moment";
import { notification, Tabs } from "antd";
import { PayloadType } from "type";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import {
  countContracts,
  countContractsSuccess,
  getContractByStatus,
  setQuery,
} from "features/contractSlice";
import TabContent from "./tabContent";

function ListContract() {
  const dispatch = useDispatch();
  const { query, contracts } = useSelector(
    (state: any) => state.contractReducer
  );
  const [countContract, setCountContract] = useState<any>({});

  const optionTabContracts = [
    {
      key: `1`,
      label: `Khách hàng đang dùng thử (${countContract?.trial})`,
      children: <TabContent record={{}} dataActiverHerd={{}} />,
    },
    {
      key: `2`,
      label: `Khách đang thuê bao (${countContract?.doing})`,
      children: <TabContent record={{}} dataActiverHerd={{}} />,
    },
    {
      key: `3`,
      label: `Khách dừng dịch vụ (${countContract?.stop})`,
      children: <TabContent record={{}} dataActiverHerd={{}} />,
    },
  ];
  //Lấy danh sách hợp đồng
  function getContractByTabStatus(queryActive: any) {
    const payload = {
      query: queryString.stringify(
        {
          ...query,
          ...queryActive,
          populate: "servicePackageIds.farmScaleTTNId farmScaleTTTId, userId",
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage: any) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getContractByStatus(payload));
  }

  //Lấy danh sách hợp đồng
  function getContractByStatuss() {
    const payload = {
      query: queryString.stringify(
        {},
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(res: any) {
          setCountContract(res);
        },
        failed(errorMessage: any) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(countContracts(payload));
  }

  useEffect(() => {
    getContractByTabStatus({ statusQuery: "trial" });
    getContractByStatuss();
  }, [query]);

  const handleChangeTab = (e: any) => {
    if (e === "1") {
      getContractByTabStatus({ statusQuery: "trial" });
    } else if (e === "2") {
      getContractByTabStatus({ statusQuery: "doing" });
    } else if (e === "3") {
      getContractByTabStatus({ statusQuery: "stop" });
    }
  };
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={optionTabContracts}
        onChange={(e) => handleChangeTab(e)}
      />
    </div>
  );
}

export default ListContract;
