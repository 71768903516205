import { Col, Row } from "antd";
import _ from "lodash";
import React from "react";

interface CustomLabelValueType {
  label: string;
  value: string | any;
  alignX?: number;
  isLabelStart?: boolean;
  isLabelEnd?: boolean;
  hasMarginleft?: boolean;
  isGrid?: boolean;
  disableColon?: boolean;
  colorStatus?: string;
}
function CustomLabelValue({
  label,
  value,
  alignX,
  hasMarginleft = true,
  isGrid = false,
  disableColon = false,
  colorStatus = undefined,
}: CustomLabelValueType) {
  if (isGrid) {
    return (
      <Row>
        <Col
          lg={12}
          sm={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>{label}</div>
          <div>{!disableColon && ":"}</div>
        </Col>
        <Col lg={12} sm={24}>
          &nbsp;{value}
        </Col>
      </Row>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        marginBottom: 24,
      }}
    >
      <label
        style={{
          display: "inline-block",
          minWidth: alignX ? `${alignX}px` : undefined,
          textAlign: "left",
          fontWeight: "600",
        }}
      >
        {label}
      </label>
      <span>{!disableColon && ":"}</span>
      <span
        className={
          _.isUndefined(colorStatus)
            ? ""
            : colorStatus === "active"
            ? "active-color"
            : "lock-color"
        }
        style={{
          display: "inline-block",
          marginLeft: hasMarginleft ? (alignX ? alignX / 2 : 24) : 24,
        }}
      >
        {value}
      </span>
    </div>
  );
}

export default CustomLabelValue;
