import { Button, Form, Input, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import {
  createProductTemps,
  updateProductTemps,
} from "features/productTempSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, TypeDataShared, SelectOptionType } from "type";
import * as yup from "yup";
import queryString from "query-string";
import { getTypeGroups } from "features/typeDataSharedSlice";
import { getSharedDatas } from "features/sharedDataSlice";
import NumberFormat from "react-currency-format";

interface CUFoodProp {
  getListFood?: any;
  rowUpdate?: any;
  setRowUpdate?: any;
  typeGroupId: any;
}
function CUFood({
  typeGroupId,
  getListFood,
  rowUpdate,
  setRowUpdate,
}: CUFoodProp) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [listDV, setListDV] = useState<SelectOptionType[]>([]);

  //validate
  const FoodSchema = yup.object().shape({
    code: yup.string().required("Mã thức ăn không được để trống!"),
    name: yup.string().required("Tên thức ăn không được để trống!"),
    price: yup.string().required("Giá tiền không được để trống!"),
    supplier: yup.string().required("Nhà cung cấp không được để trống!"),
    typeBag: yup
      .number()
      .required("Loại bao không được để trống")
      .positive("Giá trị phải là số dương!"),
    unitId: yup.string().required("Đơn vị tính không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await FoodSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = rowUpdate
    ? {
        ...rowUpdate,
      }
    : {
        code: "",
        name: "",
        supplier: "",
        price: undefined,
        unitId: undefined,
        description: "",
        typeBag: undefined,
      };

  //create handle
  function handleCreateFood(values: any) {
    const priceString = values.price;
    const priceNumber = parseFloat(priceString.replace(",", "."));

    const dataBody = {
      ...values,
      price: priceNumber, // Thay thế trường "price" bằng giá trị số
    };

    const payload: PayloadType = {
      body: {
        ...dataBody,
        productTypeId: typeGroupId,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới danh mục tên thức ăn thành công!",
          });
          form.resetFields();
          getListFood();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới danh mục thức ăn thất bại!",
              description: "Mã thức ăn đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Tạo mới danh mục thức ăn thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createProductTemps(payload));
  }
  //update handle
  function handleUpdateFood(values: TypeDataShared) {
    const payload: PayloadType = {
      params: _.get(rowUpdate, "id", ""),
      body: {
        ...values,
        productTypeId: typeGroupId,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật danh mục thức ăn thành công!",
          });
          setRowUpdate(null);
          form.resetFields();
          getListFood();
          form.resetFields();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Cập nhật danh mục thức ăn thất bại!",
              description: "Tên danh mục thức ăn đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Cập nhật danh mục thức ăn thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateProductTemps(payload));
  }

  // lấy danh sách đơn vị
  const getFieldDV = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        code: `FOOD_UNIT`,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          const typeGroupId = _.get(results, "[0].id", null);
          const payload1: PayloadType = {
            query: queryString.stringify({
              page: 1,
              limit: 999,
              typeGroupId: typeGroupId,
            }),
            callback: {
              success(results) {
                const listDV = results.results;
                if (!!listDV && Array.isArray(listDV)) {
                  setListDV(
                    listDV.map((item: any) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })
                  );
                }
              },
              failed() {},
            },
          };
          dispatch(getSharedDatas(payload1));
        },
        failed() {},
      },
    };
    dispatch(getTypeGroups(payload));
  };

  useEffect(() => {
    getFieldDV();
  }, []);

  const resetForm = () => {
    form.resetFields();
    setRowUpdate(null);
  };

  useEffect(() => {
    if (!rowUpdate) {
      form.resetFields();
    }
    form.setFieldsValue({
      ...rowUpdate,
    });
  }, [rowUpdate]);

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={
        Object.keys(rowUpdate ?? {}).length === 0
          ? handleCreateFood
          : handleUpdateFood
      }
    >
      <Form.Item required label={"Mã thức ăn "} name={"code"} rules={[yupSync]}>
        <Input placeholder="Nhập vào mã thức ăn" disabled={!!rowUpdate} />
      </Form.Item>

      <Form.Item required label={"Tên thức ăn"} name={"name"} rules={[yupSync]}>
        <Input placeholder="Nhập tên thức ăn" />
      </Form.Item>

      <Form.Item
        required
        label={"Nhà cung cấp"}
        name={"supplier"}
        rules={[yupSync]}
      >
        <Input placeholder="Nhập tên nhà cung cấp" />
      </Form.Item>

      <Form.Item required label={"Loại bao"} name={"typeBag"} rules={[yupSync]}>
        <Input placeholder="Nhập loại bao" type="number" />
      </Form.Item>

      <Form.Item
        required
        label={"Giá tiền (vnđ)"}
        name={"price"}
        rules={[yupSync]}
      >
        <NumberFormat
          style={{ border: "1px solid #d9d9d9" }}
          placeholder="Nhập giá"
          thousandSeparator={"."}
          decimalSeparator={","}
        />
      </Form.Item>
      <Form.Item
        label={"Đơn vị tính"}
        name={"unitId"}
        rules={[yupSync]}
        required
      >
        {/* !!!! */}
        <Select
          placeholder="Chọn đơn vị tính"
          style={{ width: "100%" }}
          options={listDV}
        />
      </Form.Item>

      <Form.Item label={"Ghi chú"} name={"description"}>
        <TextArea rows={3} placeholder={"Nhập ghi chú"} />
      </Form.Item>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button onClick={resetForm} style={{ width: "48%" }}>
          Huỷ bỏ
        </Button>
        <Button style={{ width: "48%" }} type="primary" htmlType="submit">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
}
export default CUFood;
