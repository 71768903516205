import { Button, Form, Input, notification, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { createTypeGroup, updateTypeGroup } from "features/typeDataSharedSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, TypeDataShared } from "type";
import { getBody } from "utils";
import * as yup from "yup";

interface CUTypeDataSharedProp {
  getTypeDataShared: () => void;
  rowUpdate: TypeDataShared | null;
  setRowUpdate: (value: TypeDataShared | null) => void;
}
function CUTypeDataShared({
  getTypeDataShared,
  rowUpdate,
  setRowUpdate,
}: CUTypeDataSharedProp) {
  const dispatch = useDispatch();
  const [form] = useForm();

  //validate
  const TypeDataSharedSchema = yup.object().shape({
    code: yup.string().required("Mã số không được để trống!"),
    name: yup.string().required("Tên nhóm thể loại không được để trống!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await TypeDataSharedSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = rowUpdate
    ? {
        ...rowUpdate,
      }
    : {
        code: "",
        name: "",
        note: "",
      };

  //create handle
  function handleCreateSection(values: TypeDataShared) {
    const payload: PayloadType = {
      body: getBody(
        {
          ...values,
        },
        []
      ),
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới nhóm thể loại thành công!",
          });
          form.resetFields();
          getTypeDataShared();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới phần thi thất bại!",
              description: "Mã số đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Tạo mới phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createTypeGroup(payload));
  }
  //update handle
  function handleUpdateSection(values: TypeDataShared) {
    const payload: PayloadType = {
      params: _.get(rowUpdate, "id", ""),
      body: {
        ...values,
      },
      callback: {
        success(values) {
          notification.success({
            message: "Cập nhật phần thi thành công!",
          });
          setRowUpdate(null);
          form.resetFields();
          getTypeDataShared();
          form.resetFields();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Cập nhật phần thi thất bại!",
              description: "Tên phần thi đã tồn tại!",
            });
            return;
          }
          notification.error({
            message: "Cập nhật phần thi thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(updateTypeGroup(payload));
  }

  const resetForm = () => {
    form.resetFields();
    setRowUpdate(null);
  };

  useEffect(() => {
    if (!rowUpdate) {
      form.resetFields();
    }
    form.setFieldsValue({
      ...rowUpdate,
    });
  }, [rowUpdate]);

  return (
    <Form
      labelCol={{ span: 16 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={
        Object.keys(rowUpdate ?? {}).length === 0
          ? handleCreateSection
          : handleUpdateSection
      }
    >
      <Form.Item required label={"Mã số"} name={"code"} rules={[yupSync]}>
        <Input placeholder="Nhập vào mã số" disabled={!!rowUpdate} />
      </Form.Item>

      <Form.Item
        required
        label={"Tên nhóm thể loại"}
        name={"name"}
        rules={[yupSync]}
      >
        <Input placeholder="Nhập tên nhóm thể loại" />
      </Form.Item>

      <Form.Item label={"Ghi chú"} name={"note"}>
        <TextArea rows={3} placeholder={"Nhập ghi chú"} />
      </Form.Item>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center" }}
      >
        <Button onClick={resetForm} style={{ width: "48%" }}>
          Huỷ bỏ
        </Button>
        <Button style={{ width: "48%" }} type="primary" htmlType="submit">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
}
export default CUTypeDataShared;
