import { useEffect, useState } from "react";
import {
  Popconfirm,
  Space,
  Typography,
  Col,
  Input,
  Row,
  Select,
  notification,
} from "antd";
const { Search } = Input;
import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, ShareDataType } from "type";
import Table, { ColumnsType } from "antd/lib/table";
import queryString from "query-string";
import {
  deleteProductTemps,
  getProductTemps,
  setQuery,
} from "features/productTempSlice";
import { deleteSharedData, getSharedDatas } from "features/sharedDataSlice";
import _ from "lodash";
import CUMedicine from "./CUMedicine";
const { Title } = Typography;
function Medicine() {
  const dispatch = useDispatch();
  const { query, productTemps } = useSelector(
    (state: any) => state.productTempReducer
  );
  const [searchText, setSearchText] = useState<any>(undefined);
  const [rowUpdate, setRowUpdate] = useState<any>(null);
  const [typeGroupId, setTypeGroupId] = useState<string>("");
  console.log("typeGroupId", typeGroupId);
  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        codeOrName: !_.isEmpty(searchText) ? searchText : undefined,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  //desc column
  const columns: ColumnsType<ShareDataType> = [
    {
      title: "Mã thuốc",
      dataIndex: "code",
      key: "code",
      align: "left",
      render(value) {
        return value;
      },
    },

    {
      title: "Tên thuốc",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Nhà cung cấp",
      dataIndex: "supplier",
      key: "supplier",
      align: "left",
    },
    {
      title: "Giá tiền (vnđ)",
      dataIndex: "price",
      key: "price",
      align: "left",
      render: (value) => {
        return (
          <div style={{ textAlign: "right" }}>
            {`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </div>
        );
      },
    },
    {
      title: "ĐV tính",
      dataIndex: ["unitId", "name"],
      key: "unitId.name",
      align: "left",
    },

    {
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
      align: "left",
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record: any) => {
        return (
          <Space>
            <a
              onClick={() => {
                setRowUpdate(record);
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    callback: {
                      success(values) {
                        notification.success({
                          message: "Xoá danh mục tên thuốc thành công!",
                        });
                        getListMedicine();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: "Xoá danh mục tên thuốc thất bại!",
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(deleteProductTemps(payload));
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  // Danh sách danh mục tên thuốc
  const getMedicines = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        code: "MEDICINE_TYPE",
      }),
      callback: {
        success(dataTypeGroup) {
          setTypeGroupId(dataTypeGroup?.results[0]?.id);
          const payload: PayloadType = {
            query: queryString.stringify({
              ...query,
              populate: "unitId",
              productTypeId: dataTypeGroup?.results[0]?.id,
            }),
            callback: {
              success() {},
              failed() {},
            },
          };
          dispatch(getProductTemps(payload));
        },
        failed() {},
      },
    };
    dispatch(getSharedDatas(payload));
  };

  useEffect(() => {
    getMedicines();
  }, [query]);

  // Lấy loại của sản phẩm hiện tại

  const getListMedicine = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        ...query,
        productTypeId: !_.isEmpty(typeGroupId) ? typeGroupId : undefined,
        populate: "unitId",
      }),
      callback: {
        success() {},
        failed() {},
      },
    };
    dispatch(getProductTemps(payload));
  };

  useEffect(() => {
    getListMedicine();
  }, [query, typeGroupId]);

  return (
    <Content className="custom-layout-content">
      <Row
        style={{
          height: "70px",
          marginBottom: "16px",
          lineHeight: "70px",
          padding: "16px 24px",
        }}
        className="background-content-layout"
      >
        <Col span={24}>
          <div className="space-between">
            <Title
              level={4}
              style={{
                marginBottom: "0px",
                height: "40px",
                lineHeight: "40px",
              }}
            >
              Danh mục tên thuốc
            </Title>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={6}>
          <div
            className="background-content-layout"
            style={{
              padding: "24px",
              marginRight: "8px",
            }}
          >
            <>
              <div className="space-between">
                <Title
                  level={4}
                  style={{
                    marginBottom: "20px",
                    height: "40px",
                    lineHeight: "40px",
                  }}
                >
                  {Object.keys(rowUpdate ?? {}).length === 0
                    ? "Thêm mới"
                    : "Cập nhật"}
                </Title>
              </div>
              <CUMedicine
                typeGroupId={typeGroupId}
                rowUpdate={rowUpdate}
                getListMedicine={getListMedicine}
                setRowUpdate={setRowUpdate}
              />
            </>
          </div>
        </Col>
        <Col span={18}>
          <div
            className=" background-content-layout"
            style={{ padding: "24px 16px", marginLeft: "8px" }}
          >
            <div
              className="space-between"
              style={{
                marginBottom: "20px",
                height: "40px",
                lineHeight: "40px",
                paddingLeft: "10px",
              }}
            >
              <Title level={5}>Danh sách</Title>
              <Space className="custom-placeholder-select">
                <Search
                  placeholder="Tìm kiếm theo mã hoặc tên thuốc"
                  onChange={(e) => setSearchText(e.target.value)}
                  onSearch={handleSearch}
                  style={{ width: 282 }}
                />
              </Space>
            </div>
            <Table
              style={{ marginLeft: "8px" }}
              className=""
              rowKey={"id"}
              columns={columns}
              dataSource={_.get(productTemps, "results", [])}
              pagination={{
                current: _.get(productTemps, "page", 1),
                pageSize: _.get(productTemps, "limit", 1),
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                total: _.get(productTemps, "totalResults", 1),
                showTotal: (total, range) => {
                  return `Có tất cả ${total} bản ghi`;
                },
                onChange(page, pageSize) {
                  dispatch(
                    setQuery({
                      ...query,
                      page: page,
                      limit: pageSize,
                    })
                  );
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Medicine;
