/**
 * Quản lí danh sách trại
 */
import { useState, useEffect } from "react";
import { Table, Typography, notification, Input, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { useHistory } from "react-router";
import { PayloadType, MangerHerdType, ModalInfoType } from "type";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getSubscribers, setQuery } from "features/subscriberSlice";
import queryString from "query-string";
import CUHerd from "./CUHerd";
import { setModal } from "features/modalSlice";
const { Title } = Typography;

function ListPigFarm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query, subscribers } = useSelector(
    (state: any) => state.subscriberReducer
  );

  const [searchText, setSearchText] = useState<string>("");

  //Lấy danh sách trại theo tài khoản người dùng
  function getHerdByUser() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }
  useEffect(() => {
    getHerdByUser();
  }, [query]);

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        phone: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const columns: ColumnsType<MangerHerdType> = [
    {
      title: "Tên chủ trại",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 250,
    },

    {
      title: "Số trại con",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: 150,
      render: (_, record: any) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record?.personalConfig?.herdIds.length}
          </p>
        );
      },
    },

    {
      title: "Địa chỉ ",
      dataIndex: "address",
      key: "address",
      width: 700,
    },
    {
      title: "Kỹ thuật nhập liệu",
      dataIndex: "dataEntryClerk.name",
      key: "dataEntryClerk.name",
      width: 700,
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 200,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                history.push({
                  pathname: "/admin/pig-farm-info/" + record?.id,
                  state: record,
                });
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Thêm trại ",
                  content: (
                    <CUHerd getHerdByUser={getHerdByUser} record={record} />
                  ),
                  dataUpdate: "",
                  size: 1200,
                };
                dispatch(setModal(payload));
              }}
            >
              Thêm trại con
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Search
            placeholder="Tìm theo mã tài khoản"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 252 }}
          />
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(subscribers, "results", [])}
        pagination={{
          total: _.get(subscribers, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListPigFarm;
