import React, { useState } from "react";
import { Button, DatePicker, notification } from "antd";
import { PayloadType } from "type";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import { runReworkSynthesisReports } from "features/statisticalSlice";
import { closeModal } from "utils";
import { setModal } from "features/modalSlice";

const { RangePicker } = DatePicker;
type Props = {
  data: {
    herdId: string;
  };
};
const ReworkSynthe = ({ data }: Props) => {
  const { herdId } = data;
  const dispatch = useDispatch();
  const [datePicker, setDatePicker] = useState({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  function runRework() {
    if (!datePicker.startDate) {
      notification.error({
        message: "Thông báo!",
        description: `Vui lòng chọn ngày bắt đầu`,
      });
      return;
    }
    if (!datePicker.endDate) {
      notification.error({
        message: "Thông báo!",
        description: `Vui lòng chọn ngày kết thúc`,
      });
      return;
    }

    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const payload: PayloadType = {
      params: herdId,
      query: queryString.stringify(datePicker),
      callback: {
        success() {
          notification.success({
            message: "Thông báo",
            description: "Đồng bộ thành công",
          });
          setIsLoading(false);
          closeModal(dispatch, setModal);
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình đồng bộ!",
            description: `Đồng bộ không thành công: ${errorMessage}`,
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(runReworkSynthesisReports(payload));
  }

  function rangePickerChange(datetimes: any) {
    setDatePicker({
      startDate: datetimes[0] ? datetimes[0].toISOString() : null,
      endDate: datetimes[1] ? datetimes[1].toISOString() : null,
    });
  }

  return (
    <div
      style={{
        margin: 6,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <RangePicker onChange={rangePickerChange} />
      <Button
        disabled={isLoading}
        loading={isLoading}
        type="primary"
        style={{ margin: "16px 0px" }}
        onClick={() => {
          runRework();
        }}
      >
        Xác nhận
      </Button>
    </div>
  );
};

export default ReworkSynthe;
