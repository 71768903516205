import { setSharedData, getSharedDatasSuccess } from "features/sharedDataSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getSharedDatas(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.shareddata}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getSharedDatasSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* getSharedData1s(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.shareddata}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getSharedDatasSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* getSharedData(data: ActionType): any {
  const { callback, body, query, params, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.shareddata}/${params}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer) yield put(setSharedData(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* createSharedData(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.shareddata;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateSharedData(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.shareddata}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteSharedData(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.shareddata}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export default function* sharedDataSaga() {
  yield takeLatest("sharedDataSlice/getSharedDatas", getSharedDatas);
  yield takeLatest("sharedDataSlice/getSharedData1s", getSharedData1s);
  yield takeLatest("sharedDataSlice/getSharedData", getSharedData);
  yield takeLatest("sharedDataSlice/createSharedData", createSharedData);
  yield takeLatest("sharedDataSlice/deleteSharedData", deleteSharedData);
  yield takeLatest("sharedDataSlice/updateSharedData", updateSharedData);
}
