import { Button, Form, Radio, Select, Upload, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType } from "type";
import { UploadFile } from "antd/es/upload";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import queryString from "query-string";
import { importHistoryPig, importPig } from "features/importSlice";
import { getBarnByHerd } from "features/herdSlice";

function importDataPig(data: any) {
  const typeImport = _.get(data, "type", "");
  const herdId = _.get(data, "data.record.id", "");
  const [form] = useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [listPig, setListPig] = useState<any[]>([]);
  const [listBarn, setListBarn] = useState<any[]>([]);
  const [excelFile, setFileExcel] = useState<any[]>([]);
  const token: any = localStorage.getItem("token");

  const fileRemoved = (event: UploadFile<any>) => {
    const filteredFiles = files.filter((file) => file !== event);
    setFiles(filteredFiles);
  };

  const handleGetBarnByHerdTab = (herdId: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        level: "1",
        parent: herdId,
      }),
      skipUpdateReducer: true,
      callback: {
        success(listScales) {
          const results = _.get(listScales, "results", []);
          if (!!results && Array.isArray(results)) {
            setListBarn(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getBarnByHerd(payload));
  };

  useEffect(() => {
    handleGetBarnByHerdTab(herdId);
  }, [herdId]);

  const infoSchema = yup.object().shape({
    file: yup.object().required("Vui lòng chọn file để tải lên!"),
    typeCode: yup.string().required("Vui lòng chọn loại lợn !"),
    herdId: yup.string().required("Vui lòng chọn chuồng nhập!"),
  });
  const yupSync = [getYupSync(infoSchema)];
  function handleSubmit() {
    const valueFile = form.getFieldsValue([
      "isEvent",
      "file",
      "typeCode",
      "herdId",
    ]);
    if (typeImport == "ImportPig") {
      handleImportPig(valueFile);
    } else {
      handleImportHistoryPig(valueFile);
    }
  }

  const handleImportPig = (valueFile: any) => {
    setLoading(true);

    const fileObj = _.get(valueFile, "file.fileList[0].originFileObj", "");

    const formData = new FormData();
    formData.append("file", fileObj);
    formData.append("herdId", valueFile.herdId);
    const payload: PayloadType = {
      query: queryString.stringify({
        typeCode: valueFile.typeCode,
      }),
      body: formData,
      skipUpdateReducer: true,
      callback: {
        success(listScales) {
          const results = _.get(listScales, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPig(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
          closeModal(dispatch, setModal);
          setLoading(false);

          notification.success({
            message: "Nhập thành công lợn!",
          });
        },
        failed(errorMessage) {
          setLoading(false);
          notification.error({
            message: "Nhập thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(importPig(payload));
  };

  const handleImportHistoryPig = (valueFile: any) => {
    setLoading(true);

    const fileObj = _.get(valueFile, "file.fileList[0].originFileObj", "");

    const formData = new FormData();
    formData.append("file", fileObj);
    formData.append("herdId", herdId);
    const payload: PayloadType = {
      query: queryString.stringify({
        typeCode: valueFile.typeCode,
        isEvent: valueFile.isEvent,
      }),
      body: formData,
      skipUpdateReducer: true,
      callback: {
        success(listScales) {
          const results = _.get(listScales, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPig(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
          setLoading(false);

          closeModal(dispatch, setModal);
          notification.success({
            message: "Nhập thành công lịch sử lợn!",
          });
        },
        failed(errorMessage) {
          notification.error({
            message: `Nhập không thành  lịch sử lợn! ${errorMessage}`,
          });
          setLoading(false);
        },
      },
    };
    dispatch(importHistoryPig(payload));
  };

  const initValues = {
    isEvent: false,
    file: undefined,
    typeCode: undefined,
    herdId: undefined,
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      autoComplete="off"
      initialValues={initValues}
    >
      <div className="ant-modal-body">
        {typeImport !== "ImportPig" && (
          <Form.Item label={"Thêm vào LS : "} name={"isEvent"}>
            <Radio.Group>
              <Radio value={false}>Phối giống</Radio>
              <Radio value={true}>Sự kiện</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          required
          label={"File danh sách : "}
          name={"file"}
          rules={yupSync}
        >
          <Upload
            name="file"
            maxCount={1}
            showUploadList={{ showRemoveIcon: true }}
            accept=".xls, .xlsx"
            beforeUpload={() => false}
            onChange={async (e) => {
              form.setFieldValue("fileName", _.get(e, "fileList", ""));
              try {
                if (_.isEmpty(e)) return;
                const files = await Promise.all([...e.fileList]);

                setFileExcel(files.map((file: any) => Object.assign(file)));
              } catch (error) {
                //
              }
            }}
            onRemove={(e) => fileRemoved(e)}
          >
            <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
              Bấm để tải lên
            </Button>
            <p
              style={{
                fontSize: "13px",
                paddingTop: "5px",
                textDecoration: "underline",
                color: "dodgerblue",
              }}
            >
              <i>(Tải file mẫu .xslx)</i>
            </p>
          </Upload>
        </Form.Item>

        <Form.Item
          required
          label={"Loại lợn"}
          name={"typeCode"}
          rules={yupSync}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Chọn loại lợn"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            allowClear
            options={[
              { label: "Lợn Nái", value: "LN" },
              { label: "Lợn Đực", value: "LD" },
            ]}
          />
        </Form.Item>
        {typeImport === "ImportPig" ? (
          <Form.Item required label={"Chuồng"} name={"herdId"} rules={yupSync}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Chọn chuồng nhập"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              allowClear
              options={listBarn}
            />
          </Form.Item>
        ) : (
          ""
        )}
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          Tải lên
        </Button>
      </div>
    </Form>
  );
}

export default importDataPig;
