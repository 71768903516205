import { getStatisticalSuccess } from "features/statisticalSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
const _ = require("lodash");
export function* getStatisticals(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.statisticals}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer) callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* getStatisticalByHerd(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.statisticalByHerd}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer) callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* getStatisticalGuest(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.pigs}/guest/?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer) callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* createStatistical(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.statisticals;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}

export function* updateStatistical(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.statisticals}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export function* deleteStatistical(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.statisticals}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success();
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* runReworkSynthesisReports(data: ActionType): any {
  const { callback, params, query } = data.payload;
  const url = `synthesisReports/runReworkSynthesisReports/${params}?${query}`;
  try {
    const res = yield call(PATCH, url);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success();
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}
export default function* statisticalSaga() {
  yield takeLatest("statisticalSlice/getStatisticals", getStatisticals);
  yield takeLatest(
    "statisticalSlice/getStatisticalByHerd",
    getStatisticalByHerd
  );
  yield takeLatest("statisticalSlice/getStatisticalGuest", getStatisticalGuest);
  yield takeLatest("statisticalSlice/updateStatistical", updateStatistical);
  yield takeLatest("statisticalSlice/createStatistical", createStatistical);
  yield takeLatest("statisticalSlice/deleteStatistical", deleteStatistical);
  yield takeLatest(
    "statisticalSlice/runReworkSynthesisReports",
    runReworkSynthesisReports
  );
}
