import { getHerdTypesSuccess } from "features/herdTypeSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getHerdTypes(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.herdTypes}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getHerdTypesSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* createHerdTypes(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.herdTypes;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateHerdTypes(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.herdTypes}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteHerdTypes(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.herdTypes}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* parameterSaga() {
  yield takeLatest("herdTypeSlice/getHerdTypes", getHerdTypes);
  yield takeLatest("herdTypeSlice/createHerdTypes", createHerdTypes);
  yield takeLatest("herdTypeSlice/updateHerdTypes", updateHerdTypes);
  yield takeLatest("herdTypeSlice/deleteHerdTypes", deleteHerdTypes);
}
