import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Select,
  Table,
  Layout,
  Typography,
  notification,
  Switch,
  Input,
  Space,
  Tabs,
} from "antd";
import _ from "lodash";
import moment from "moment";
const { Content } = Layout;
import { setModal } from "features/modalSlice";
import CustomLabelValue from "pages/components/CustomLabelValue";
import { useDispatch } from "react-redux";
import { closeModal } from "utils";
function formatCurrency(price: number, locale = "vi-VN", currency = "VND") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(price);
}
function Support(dataView: any) {
  const record = dataView?.record;
  const dispatch = useDispatch();
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 20,
        marginTop: 16,
        marginRight: 20,
        // backgroundColor: "white",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Row
          style={{
            paddingTop: 16,
            paddingLeft: 20,
            paddingRight: 20,
            background: "rgba(242, 242, 242, 0.50)",
          }}
        >
          <Col span={24}>
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Hỗ trợ kỹ thuật"
              value={_.get(record, "code", "")}
            />
            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Hỗ trợ nhập liệu"
              value={_.get(record, "userId.name", "")}
            />

            <CustomLabelValue
              hasMarginleft={false}
              alignX={200}
              label="Hỗ trợ kinh doanh"
              value={record?.servicePackageIds
                .map((item: any) => item.name)
                .join(", ")}
            />
          </Col>
        </Row>
        <div className="ant-modal-footer">
          <Button
            onClick={() => {
              closeModal(dispatch, setModal);
            }}
          >
            Quay lại
          </Button>
        </div>
      </Space>
    </Content>
  );
}

export default Support;
