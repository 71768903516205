/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { ProvinceType } from "type";

/*
 * Declare type of data
 */
interface provinceDataState {
  provinces: ProvinceType[]; //
  province: ProvinceType | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: provinceDataState = {
  provinces: [],
  province: null,
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager province
 **/

export const provinceSlice: any = createSlice({
  name: "provinceSlice",
  initialState: initialState,
  reducers: {
    getProvinces: (state, action) => {
      state.isLoading = true;
    },
    getProvincesSuccess: (state, action) => {
      state.province = action.payload;
      state.isLoading = false;
    },
    getProvince: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const { getProvinces, getProvincesSuccess, getProvince } =
  provinceSlice.actions;
export default provinceSlice.reducer;
