const {
  axiosClient,
  axiosClientFile,
  axiosClientProvinces,
} = require("./axiosClient");
const { axiosGraphqlClient } = require("./axiosGraphqlClient");
export const GET = (url, params) => {
  return axiosClient.get(url, params);
};
export const GET_PROVINCES = (url, params) => {
  return axiosClientProvinces.get(url, params);
};

export const POST = (url, body) => {
  return axiosClient.post(url, body);
};

export const PATCH = (url, body) => {
  return axiosClient.patch(url, body);
};

export const PUT = (url, body) => {
  return axiosClient.put(url, body);
};

export const DELETE = (url, params) => {
  return axiosClient.delete(url, params);
};

export const POST_GRAPHQL = (url, body) => {
  return axiosGraphqlClient.post(url, body);
};

export const GET_FILE = (url, params) => {
  return axiosClientFile.get(url, params);
};
export const POST_FILE = (url, data) => {
  return axiosClientFile.post(url, data);
};

export default {
  GET,
  POST,
  PATCH,
  DELETE,
  POST_GRAPHQL,
  GET_FILE,
  POST_FILE,
  GET_PROVINCES,
};
