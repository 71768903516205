/**
 * Màn hình danh sách người dùng
 */
import { memo } from "react";
import { Layout, Typography, Space } from "antd";
import _ from "lodash";
import ListProcess from "pages/Process/ListProcess/index";
const { Title } = Typography;
const { Content } = Layout;
function Process() {
  return (
    <Content
      className="custom-layout-content background-content-layout"
      style={{
        marginLeft: 24,
        marginTop: 16,
        marginRight: 24,
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        <div className="space-between ">
          <Title style={{ marginBottom: 0 }} level={4}>
            Quản trị quy trình
          </Title>
        </div>
        <ListProcess />
      </Space>
    </Content>
  );
}

export default memo(Process);
