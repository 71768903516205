import { Button, Form, Input, Radio, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch } from "react-redux";
import { PayloadType, Role, SelectOptionType, UserType } from "type";
import { closeModal, getBody, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import type { DatePickerProps } from "antd";
import moment from "moment";
import { DatePicker, Space } from "antd";
import _ from "lodash";
import { getPromotions } from "features/promotionsSlice";
import { getServicePackages } from "features/servicePackageSlice";
import type { RangePickerProps } from "antd/es/date-picker";
const dateFormat = "DD/MM/YYYY";
import {
  createPromotionDetails,
  updatePromotionDetails,
  getPromotionDetails,
} from "features/promotionDetailSlice";
import queryString from "query-string";
import { useSelector } from "react-redux";
interface DataType {
  code: string;
  servicePackId: string;
  amount: number;
  startDate: string;
  endDate: string;
  promotionId: string;
}
interface UserInfo {
  idUpdate?: string;
  record?: DataType;
}
function CUPromotionDetail({
  idUpdate = undefined,
  record = undefined,
}: UserInfo) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const [disableDate, setDisableDate] = useState(true);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listRoles, setListRoles] = useState<SelectOptionType[]>([]);
  const [listPromotion, setListPromotion] = useState<SelectOptionType[]>([]);
  const [promotionActive, setPromotionActive] = useState({});
  const [listServicePack, setListServicePack] = useState<SelectOptionType[]>(
    []
  );

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (record && !_.isUndefined(idUpdate)) {
      form.setFieldValue("code", _.get(record, "code", null));
      form.setFieldValue("servicePackId", _.get(record, "servicePackId", null));
      form.setFieldValue("amount", _.get(record, "amount", null));
      form.setFieldValue("promotionId", _.get(record, "promotionId.id", null));
      form.setFieldValue("startDate", moment(_.get(record, "startDate", "")));
      form.setFieldValue("endDate", moment(_.get(record, "endDate", "")));
      setDisableDate(false);
    } else {
      form.setFieldsValue({
        code: null,
        servicePackId: null,
        amount: null,
        startDate: null,
        endDate: null,
        promotionId: _.get(record, "id", null),
      });
    }
  }, [record, open]);
  //Lấy danh sách tài khoản
  function handleGetPromotionDetail() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "promotionId",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  }
  const formSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã khuyến mãi!"),
    amount: yup
      .number()
      .typeError("Vui lòng nhập số lượng !")
      .required("Vui lòng nhập số lượng !")
      .min(1, "Số tháng phải là số dương và lớn hơn 0"),

    promotionId: yup
      .string()
      .required("Vui lòng chọn chương trình khuyến mãi!"),
    startDate: yup
      .date()
      .required("Vui lòng nhập ngày bắt đầu!")
      .test(
        "testDetailPromotion",
        "Thời gian bắt đầu không hợp lệ, Vui lòng kiểm tra lại thời gian chương trình khuyến mãi",
        () => {
          if (_.isUndefined(record)) {
            const records = listPromotion.find(
              (item) => item.value == form.getFieldValue("promotionId")
            );
            const timeStart = moment(form.getFieldValue("startDate"));
            const timeStartPromotionRoot = moment(
              _.get(records, "startDate", "")
            );
            const timeEndPromotionRoot = moment(_.get(records, "endDate", ""));

            // Kiểm tra xem timeStart nằm trong khoảng từ timeStartPromotionRoot đến timeEndPromotionRoot (bao gồm cả 2 ngày đầu và cuối)
            const isInRange =
              timeStart.isSameOrAfter(timeStartPromotionRoot, "day") &&
              timeStart.isSameOrBefore(timeEndPromotionRoot, "day");

            return isInRange;
          } else {
            const timeStart = moment(form.getFieldValue("startDate"));
            const timeStartPromotionRoot = moment(
              _.get(record, "startDate", "")
            );
            const timeEndPromotionRoot = moment(_.get(record, "endDate", ""));

            // Kiểm tra xem timeStart nằm trong khoảng từ timeStartPromotionRoot đến timeEndPromotionRoot (bao gồm cả 2 ngày đầu và cuối)
            const isInRange =
              timeStart.isSameOrAfter(timeStartPromotionRoot, "day") &&
              timeStart.isSameOrBefore(timeEndPromotionRoot, "day");

            return isInRange;
          }
        }
      ),
    endDate: yup
      .date()
      .required("Vui lòng nhập ngày kết thúc!")
      .test(
        "testDetailPromotion",
        "Thời gian kết thúc phải lớn hơn thời gian bắt đầu và nằm trong CTKM",
        () => {
          if (_.isUndefined(record)) {
            const records = listPromotion.find(
              (item) => item.value == form.getFieldValue("promotionId")
            );
            const timeStart = moment(form.getFieldValue("endDate"));

            const timeStartPromotionRoot = moment(
              _.get(records, "startDate", "")
            );

            const timeEndPromotionRoot = moment(_.get(records, "endDate", ""));

            // Kiểm tra xem timeStart nằm trong khoảng từ timeStartPromotionRoot đến timeEndPromotionRoot (bao gồm cả 2 ngày đầu và cuối)
            const isInRange =
              timeStart.isSameOrAfter(timeStartPromotionRoot, "day") &&
              timeStart.isSameOrBefore(timeEndPromotionRoot, "day");

            return isInRange;
          } else {
            const timeStart = moment(form.getFieldValue("endDate"));
            const timeStartPromotionRoot = moment(
              form.getFieldValue("startDate")
            );
            const timeEndPromotionRoot = moment(_.get(record, "endDate", ""));

            // Kiểm tra xem timeStart nằm trong khoảng từ timeStartPromotionRoot đến timeEndPromotionRoot (bao gồm cả 2 ngày đầu và cuối)
            const isInRange =
              timeStart.isSameOrAfter(timeStartPromotionRoot, "day") &&
              timeStart.isSameOrBefore(timeEndPromotionRoot, "day");

            return isInRange;
          }
        }
      ),
  });
  const yupSync = [getYupSync(formSchema)];
  function handleSubmit() {
    const values = form.getFieldsValue([
      "code",
      "servicePackId",
      "amount",
      "promotionId",
      "startDate",
      "endDate",
    ]);
    if (!idUpdate) {
      handleCreateUser(values);
      return;
    }
    handleUpdateUser(values);
  }
  function handleCreateUser(values: UserType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              body: {
                ...values,
              },
              callback: {
                success(values) {
                  notification.success({
                    message: "Tạo mới chi tiết khuyến mãi thành công!",
                  });
                  handleGetPromotionDetail();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể  tạo mới chi tiết khuyến mãi!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(createPromotionDetails(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể  tạo mới chi tiết khuyến mãi!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }
  function handleUpdateUser(values: UserType) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const payload: PayloadType = {
              params: idUpdate,
              body: getBody(
                {
                  ...values,
                },
                []
              ),
              callback: {
                success(values) {
                  notification.success({
                    message:
                      "Cập nhật thông tin chi tiết khuyến mãi thành công!",
                  });
                  handleGetPromotionDetail();
                  closeModal(dispatch, setModal);
                },
                failed(errorMessage) {
                  notification.error({
                    message: "Không thể  tạo mới chi tiết khuyến mãi!",
                    description: errorMessage,
                  });
                },
              },
            };
            dispatch(updatePromotionDetails(payload));
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin Admin!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }

  // Lấy options cho ô select roles
  const getFieldRoles = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          if (!!results && Array.isArray(results)) {
            setListRoles(
              results.map((item: any) => {
                return {
                  label: item?.name,
                  value: item?.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getRoles(payload));
  };

  useEffect(() => {
    getFieldRoles();
  }, []);

  // LẤY DANH SÁCH CÁC CHƯƠNG TRÌNH KHUYẾN MÃI
  const getListPromotion = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPromotion(
              results.map((item: any) => {
                return {
                  label: item?.name,
                  value: item?.id,
                  startDate: item?.startDate,
                  endDate: item?.endDate,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getPromotions(payload));
  };
  useEffect(() => {
    getListPromotion();
  }, []);
  // LẤY DANH SÁCH CÁC gói dịch vụ
  const getListServicePack = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(roleId) {
          const results = _.get(roleId, "results", []);
          if (!!results && Array.isArray(results)) {
            setListServicePack(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getServicePackages(payload));
  };
  useEffect(() => {
    getListServicePack();
  }, []);
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current <= moment().endOf("day");
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Form.Item
          required
          label={"Mã khuyến mãi: "}
          name={"code"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào mã khuyến mãi" disabled={!!idUpdate} />
        </Form.Item>

        <Form.Item
          required
          label={"Số lượng: "}
          name={"amount"}
          rules={yupSync}
        >
          <Input placeholder="Nhập vào số lượng " />
        </Form.Item>
        <Form.Item
          label={"Chương trình KM: "}
          name={"promotionId"}
          rules={yupSync}
          required
        >
          <Select
            allowClear
            placeholder="Chọn chương trình khuyến mãi"
            style={{ width: "100%" }}
            disabled={record && _.isUndefined(idUpdate) ? true : false}
            options={listPromotion}
          />
        </Form.Item>
        <Form.Item
          required
          label={"Thời gian bắt đầu: "}
          name={"startDate"}
          rules={yupSync}
        >
          <DatePicker
            format={dateFormat}
            placeholder="Chọn ngày bắt đầu"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          required
          label={"Thời gian kết thúc: "}
          name={"endDate"}
          rules={yupSync}
        >
          <DatePicker
            format={dateFormat}
            placeholder="Chọn ngày kết thúc"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUPromotionDetail;
