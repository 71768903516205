import { Button, Form, Input, Select, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
const { TextArea } = Input;
import { createSharedData, updateSharedData } from "features/sharedDataSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { PayloadType, ListDataSharedType, SelectOptionType } from "type";
import { getBody } from "utils";
import * as yup from "yup";
import { useEffect } from "react";

interface CUListDataSharedProp {
  getListSharedData: () => void;
  setRowUpdate: (value: ListDataSharedType | null) => void;
  rowUpdate: ListDataSharedType | null;
  typeGroupOptions: SelectOptionType[] | [];
}

function CUListDataShared({
  getListSharedData,
  setRowUpdate,
  rowUpdate,
  typeGroupOptions,
}: CUListDataSharedProp) {
  const dispatch = useDispatch();
  const [form] = useForm();

  useEffect(() => {
    if (!rowUpdate) {
      form.resetFields();
    }
    form.setFieldValue("code", _.get(rowUpdate, "code", ""));
    form.setFieldValue("name", _.get(rowUpdate, "name", ""));
    form.setFieldValue("note", _.get(rowUpdate, "note", ""));
    form.setFieldValue("typeGroupId", _.get(rowUpdate, "typeGroupId.id"));
  }, [rowUpdate]);

  //validate data from add
  const ListDataSharedSchema = yup.object().shape({
    code: yup.string().required("Mã dữ liệu không được để trống!"),
    name: yup.string().required("Tên dữ liệu  không được để trống!"),
    typeGroupId: yup.string().required(" Vui lòng chọn nhóm thể loại!"),
  });

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ListDataSharedSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const initValues = rowUpdate
    ? {
        ...rowUpdate,
      }
    : {
        code: "",
        name: "",
        note: "",
        typeGroupId: null,
      };

  //Tạo mới
  function handleCreateSharedData(values: ListDataSharedType) {
    const payload: PayloadType = {
      body: getBody(
        {
          ...values,
        },
        []
      ),
      callback: {
        success() {
          notification.success({
            message: "Tạo mới danh mục dữ liệu thành công!",
          });
          form.resetFields();
          getListSharedData();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Tạo mới danh mục dữ liệu thất bại!",
              description: errorMessage,
            });
            return;
          }
          notification.error({
            message: "Tạo mới danh mục dữ liệu thất bại!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(createSharedData(payload));
  }

  function handleUpdateSharedData(values: ListDataSharedType) {
    const payload: PayloadType = {
      params: _.get(rowUpdate, "id", ""),
      body: {
        ...values,
      },
      callback: {
        success() {
          notification.success({
            message: "Cập nhật danh mục dữ liệu dùng chung thành công!",
          });
          setRowUpdate(null);
          form.resetFields();
          getListSharedData();
          form.resetFields();
        },
        failed(errorMessage) {
          if (errorMessage?.includes("duplicate key")) {
            notification.error({
              message: "Cập nhật danh mục dữ liệu dùng chung thất bại!",
            });
            return;
          }
          notification.error({
            message: "Cập nhật danh mục dữ liệu dùng chung thất bại!",
          });
        },
      },
    };
    dispatch(updateSharedData(payload));
  }

  return (
    <Form
      labelCol={{ span: 8 }}
      layout="vertical"
      initialValues={initValues}
      labelAlign="left"
      form={form}
      onFinish={!rowUpdate ? handleCreateSharedData : handleUpdateSharedData}
    >
      <Form.Item required label={"Mã số"} name={"code"} rules={[yupSync]}>
        <Input placeholder="Nhập vào mã dữ liệu" disabled={!!rowUpdate} />
      </Form.Item>
      <Form.Item
        required
        label={"Nhóm thể loại"}
        name={"typeGroupId"}
        rules={[yupSync]}
      >
        <Select
          placeholder={"Chọn nhóm thể loại"}
          options={typeGroupOptions.map((item: SelectOptionType) => {
            return {
              label: _.get(item, "name", ""),
              value: item.value,
            };
          })}
        />
      </Form.Item>

      <Form.Item required label={"Tên"} name={"name"} rules={[yupSync]}>
        <Input placeholder="Nhập vào tên" />
      </Form.Item>
      <Form.Item label={"Ghi chú"} name={"note"}>
        <TextArea rows={3} placeholder={"Nhập ghi chú"} />
      </Form.Item>
      <div
        className="ant-modal-footer"
        style={{ padding: "10px 0px", textAlign: "center", borderTop: "none" }}
      >
        <Button
          onClick={() => {
            form.resetFields();
            setRowUpdate(null);
          }}
          style={{ width: "48%" }}
        >
          Huỷ bỏ
        </Button>
        <Button style={{ width: "48%" }} type="primary" htmlType="submit">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
}
export default CUListDataShared;
