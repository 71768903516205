/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { TypeGroup } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  typeGroups: TypeGroup[]; //
  typeGroup1s: TypeGroup[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  typeGroups: [],
  typeGroup1s: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager type group
 **/
export const typeGroupSlice: any = createSlice({
  name: "typeGroupSlice",
  initialState: initialState,
  reducers: {
    getTypeGroups: (state, action) => {
      state.isLoading = true;
    },

    getTypeGroup1s: (state, action) => {
      state.isLoading = true;
    },

    getTypeGroupsSuccess: (state, action) => {
      state.typeGroups = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
  },
});
export const { getTypeGroups, getTypeGroupsSuccess, setQuery, getTypeGroup1s } =
  typeGroupSlice.actions;
export default typeGroupSlice.reducer;
