/**
 * Tab Nhóm khác
 */
import { useState, useEffect } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { ModalInfoType, PayloadType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUProcess from "pages/Process/ListProcess/CUProcess";
import RProcess from "pages/Process/ListProcess/RProcess";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getProcedures,
  setQuery,
  deleteProcedures,
} from "features/procedureSlice";
import queryString from "query-string";
const { Title } = Typography;

function ListProcess() {
  const dispatch = useDispatch();
  const { query, procedures } = useSelector(
    (state: any) => state.procedureReducer
  );

  //Lấy danh sách quy TRÌNH !!!
  function handleGetListProcess() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "actionIds",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProcedures(payload));
  }
  useEffect(() => {
    handleGetListProcess();
  }, [query]);

  const columns: ColumnsType<any> = [
    {
      title: "Mã quy trình",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "Tên quy trình",
      dataIndex: "name",
      key: "name",
      width: 900,
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Xem thông tin quy trình",
                  content: <RProcess idUpdate={record?.id} record={record} />,
                  size: 1000,
                };
                dispatch(setModal(payload));
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                record = {
                  ...record,
                };

                const payload: ModalInfoType = {
                  open: true,
                  title: "Sửa quy trình",
                  content: (
                    <CUProcess idUpdate={record?.id ?? false} record={record} />
                  ),
                  size: 1131,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? false;
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    callback: {
                      success(values) {
                        notification.success({
                          message: "Xoá quy trình thành công!",
                        });
                        handleGetListProcess();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: "Xoá quy trình thất bại!",
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(deleteProcedures(payload));
                }
              }}
            >
              <a>Xoá</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm quy trình",
                content: <CUProcess idUpdate={undefined} />,
                size: 1331,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(procedures, "results", [])}
        pagination={{
          total: _.get(procedures, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default ListProcess;
