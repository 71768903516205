/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { ListDataSharedType } from "type";

/*
 * Declare type of data
 */
interface SharedDataState {
  sharedDatas: ListDataSharedType[]; //
  sharedData1s: ListDataSharedType[]; //
  sharedData: ListDataSharedType | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: SharedDataState = {
  sharedDatas: [],
  sharedData1s: [],
  sharedData: null,
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager shared data
 **/
export const sharedDataSlice: any = createSlice({
  name: "sharedDataSlice",
  initialState: initialState,
  reducers: {
    getSharedDatas: (state, action) => {
      state.isLoading = true;
    },

    getSharedData1s: (state, action) => {
      state.isLoading = true;
    },

    getSharedData: (state, action) => {
      state.isLoading = true;
    },

    setSharedData: (state, action) => {
      state.sharedData = action.payload;
      state.isLoading = false;
    },

    updateSharedData: (state, action) => {
      state.isLoading = true;
    },
    getSharedDatasSuccess: (state, action) => {
      state.sharedDatas = action.payload;
      state.isLoading = false;
    },
    createSharedData: (state, action) => {
      state.isLoading = true;
    },
    deleteSharedData: (state, action) => {
      state.isLoading = true;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    setQueryFilter: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
  },
});
export const {
  getSharedDatas,
  getSharedData1s,
  getSharedData,
  setSharedData,
  updateSharedData,
  getSharedDatasSuccess,
  createSharedData,
  deleteSharedData,
  setQuery,
  setQueryFilter,
} = sharedDataSlice.actions;
export default sharedDataSlice.reducer;
