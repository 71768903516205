/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Promotion } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  promotions: Promotion[]; //
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  promotions: [],
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager promotion
 **/
export const promotionSlice: any = createSlice({
  name: "promotionSlice",
  initialState: initialState,
  reducers: {
    getPromotions: (state, action) => {
      state.isLoading = true;
    },
    getPromotionsSuccess: (state, action) => {
      state.promotions = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    createPromotions: (state, action) => {
      state.isLoading = true;
    },
    updatePromotions: (state, action) => {
      state.isLoading = true;
    },
    deletePromotions: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  createPromotions,
  updatePromotions,
  deletePromotions,
  getPromotions,
  getPromotionsSuccess,
  setQuery,
} = promotionSlice.actions;
export default promotionSlice.reducer;
