/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { Config } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  configs: Config[]; //
  config: Config | null;
  query: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  configs: [],
  config: null,
  isLoading: false,
  query: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager config values
 **/
export const configSlice: any = createSlice({
  name: "configSlice",
  initialState: initialState,
  reducers: {
    getConfigs: (state, action) => {
      state.isLoading = true;
    },
    getConfig: (state, action) => {
      state.isLoading = true;
    },
    getConfigsSuccess: (state, action) => {
      state.configs = action.payload;
      state.isLoading = false;
    },
    getConfigSuccess: (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
      state.isLoading = false;
    },
    setStateConfig: (state: any, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[`${key}`] = action.payload[`${key}`];
      });
    },
    createConfig: (state, action) => {
      state.isLoading = true;
    },
    updateConfig: (state, action) => {
      state.isLoading = true;
    },
    deleteConfig: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const {
  getConfigs,
  getConfig,
  updateConfig,
  getConfigsSuccess,
  getConfigSuccess,
  setQuery,
  createConfig,
  deleteConfig,
  setStateConfig,
} = configSlice.actions;
export default configSlice.reducer;
