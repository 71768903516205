import {
  getFeedbacksSuccess,
  getFeedbackSuccess,
} from "features/feedBackSlice";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { GET, PATCH } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
const _ = require("lodash");
export function* getFeedbacks(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.feedback}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getFeedbacksSuccess(_.get(res, "data", [])));
      callback.success(_.get(res, "data", []));
    }
  } catch (error: any) {
    callback.failed(error?.message ?? "");
  }
}

export function* updateFeedback(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.feedback}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
      yield put(getFeedbackSuccess(_.get(res, "data", {})));
    }
  } catch (error: any) {
    callback.failed(error?.response?.data?.message ?? "");
  }
}
export default function* feedbackSaga() {
  yield takeLatest("feedBackSlice/getFeedbacks", getFeedbacks);
  yield takeLatest("feedBackSlice/updateFeedback", updateFeedback);
}
