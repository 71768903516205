import { getProductTempsSuccess } from "features/productTempSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getProductTemps(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.productTemps}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getProductTempsSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* createProductTemps(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.productTemps;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateProductTemps(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.productTemps}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteProductTemps(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.productTemps}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* productTempSaga() {
  yield takeLatest("productTempSlice/getProductTemps", getProductTemps);
  yield takeLatest("productTempSlice/createProductTemps", createProductTemps);
  yield takeLatest("productTempSlice/updateProductTemps", updateProductTemps);
  yield takeLatest("productTempSlice/deleteProductTemps", deleteProductTemps);
}
