import {
  Button,
  Form,
  Typography,
  Select,
  Col,
  Row,
  notification,
  Input,
  Popconfirm,
  Table,
  Space,
  Radio,
} from "antd";
import { CloseCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import React from "react";
import type { ColumnsType } from "antd/es/table";
const { Title } = Typography;
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, Role, SelectOptionType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getRoles } from "features/roleSlice";
import _ from "lodash";
import queryString from "query-string";
import { getHerdTypes } from "features/herdTypeSlice";
import { getSharedDatas } from "features/sharedDataSlice";

import {
  getProcedures,
  updateProcedures,
  createProcedures,
} from "features/procedureSlice";

function CUProcess({ idUpdate, record }: any) {
  const [form] = useForm();
  const [formRight] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.procedureReducer);

  const { open } = useSelector((state: any) => state.modalReducer);
  const [listHerdType, setListHerdType] = useState<any[]>([]);
  const [donVi, setDonVi] = useState<any[]>([]);
  const [tanSuat, setTanSuat] = useState<any[]>([]);
  const [addDatas, setAddDatas] = useState<any[]>([]);

  //Lấy danh sách quy trình
  function handleGetProcess() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
          populate: "actionIds",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(values) {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getProcedures(payload));
  }

  useEffect(() => {}, [addDatas]);

  // lấy danh sách loại trại
  const getListHerdType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(herdType) {
          const results = _.get(herdType, "results", []);
          if (!!results && Array.isArray(results)) {
            setListHerdType(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách loại trại! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getHerdTypes(payload));
  };
  useEffect(() => {
    getListHerdType();
  }, []);

  // lấy thông tin cần thiết, đơn vị,tần suất

  const getDataByGroupType = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 999,
        populate: "typeGroupId",
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            results.forEach((item: any) => {
              if (!!item.typeGroupId && item.typeGroupId.code === "TIME_UNIT") {
                setDonVi((prevData) => [...prevData, item]);
              }
              if (!!item.typeGroupId && item.typeGroupId.code === "FREQUENCY") {
                setTanSuat((prevData) => [...prevData, item]);
              }
            });
          }
        },
        failed(errorMessage) {},
      },
    };
    dispatch(getSharedDatas(payload));
  };
  useEffect(() => {
    getDataByGroupType();
  }, []);

  const formLeftSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã quy trình!"),
    name: yup.string().required("Vui lòng nhập tên quy trình!"),
    appliedTo: yup.string().required("Vui lòng chọn đối tượng áp dụng!"),
    // excutionTagIds: yup.string().required("Vui lòng chọn loại trại!"),
  });

  const formRightSchema = yup.object().shape({
    timeUnitId: yup.string().required("Vui lòng chọn đơn vị!"),
    value: yup
      .number()
      .typeError("Vui lòng nhập số !")
      .required("Vui lòng nhập giá trị là số !")
      .min(1, "Số tháng phải là số dương và lớn hơn 0"),
    frequencyId: yup.string().required("Vui lòng chọn tần suất!"),

    note: yup.string().required("Vui lòng nhập nội dung thực hiện!"),
  });

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (idUpdate) {
      form.setFieldValue("name", record?.name);
      form.setFieldValue("code", record?.code);
      form.setFieldValue("appliedTo", record?.appliedTo);
      // form.setFieldValue("excutionTagIds", record?.excutionTagIds[0]);
      const addData = record?.actionIds;
      const data: any = [];
      addData.map((item: any) => {
        const dataAdd = item?.addData;
        const aaaaa = dataAdd.map((itemChild: any) => {
          return {
            [itemChild.code]: itemChild?.param?.value,
          };
        });

        const output = aaaaa.reduce((result: any, item: any) => {
          Object.assign(result, item);
          return result;
        }, {});
        data.push(output);
      });
      setAddDatas(data);
    }
  }, [record, open]);

  const leftYupSync = [getYupSync(formLeftSchema)];
  const rightYupSync = [getYupSync(formRightSchema)];

  function handleSubmit() {
    const values = form.getFieldsValue(["code", "appliedTo", "name"]);
    if (!idUpdate) {
      handleCreateUser(values);
      return;
    }
    handleUpdateUser(values);
  }

  function handleCreateDetailProcess() {
    const values = formRight.getFieldsValue([
      "value",
      "timeUnitId",
      "frequencyId",
      "note",
    ]);
    setAddDatas([...addDatas, values]);
    formRight.setFieldValue("value", null);
    formRight.setFieldValue("frequencyId", null);
    formRight.setFieldValue("timeUnitId", null);
    formRight.setFieldValue("note", null);
  }

  function handleCreateUser(values: any) {
    if (addDatas.length > 0) {
      const payload: PayloadType = {
        body: {
          ...values,
          addDatas: addDatas,
        },
        callback: {
          success(values) {
            notification.success({
              message: "Tạo mới thông tin chi tiết thành công!",
            });
            setAddDatas([]);
            handleGetProcess();
            closeModal(dispatch, setModal);
          },
          failed(errorMessage) {
            notification.error({
              message: "Không thể  tạo mới thông tin chi tiết!",
              description: errorMessage,
            });
          },
        },
      };
      dispatch(createProcedures(payload));
    } else {
      notification.error({
        message:
          "Vui lòng tạo 1 quy trình chi tiết trước khi tiến hành thêm quy trình !",
      });
    }
  }
  function handleUpdateUser(values: any) {
    const payload: PayloadType = {
      callback: {
        success(roles) {
          const results = _.get(roles, "results", null);
          if (!!results && _.isArray(results) && results.length > 0) {
            const roleUser: Role | null = results.find(
              (item: Role) => item.name === "user"
            );
            if (roleUser) {
              const payload: PayloadType = {
                params: idUpdate,
                body: {
                  name: _.get(values, "name", null),
                  code: _.get(values, "code", null),
                  // excutionTagIds: [_.get(values, "excutionTagIds", null)],
                  appliedTo: [_.get(values, "appliedTo", null)],
                  addDatas: addDatas,
                },
                callback: {
                  success(values) {
                    notification.success({
                      message:
                        "Cập nhật thông tin thông tin chi tiết thành công!",
                    });
                    handleGetProcess();
                    closeModal(dispatch, setModal);
                  },
                  failed(errorMessage) {
                    notification.error({
                      message:
                        "Không thể cập nhật thông tin thông tin chi tiết!",
                      description: errorMessage,
                    });
                  },
                },
              };
              dispatch(updateProcedures(payload));
            }
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể cập nhật thông tin thông tin chi tiết!",
            description: "Thiếu quyền!",
          });
        },
      },
    };
    dispatch(getRoles(payload));
  }

  const columns: ColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "timeUnitId",
      key: "timeUnitId",
      width: "20%",
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      width: "20%",
    },
    {
      title: "Tần suất",
      dataIndex: "frequencyId",
      key: "frequencyId",
      width: "20%",
    },
    {
      title: "Nội dung thực hiện",
      dataIndex: "note",
      key: "note",
      width: "35%",
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "5%",
      align: "left",
      render: (val, record: any, index: any) => {
        return (
          <Space size="middle">
            <Popconfirm
              title={"Bạn có chắc chắn muốn xóa mục này không ?"}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                setAddDatas([
                  ...addDatas.slice(0, index),
                  ...addDatas.slice(index + 1),
                ]);
              }}
            >
              <a style={{ color: "red" }}>
                {React.createElement(CloseCircleOutlined)}
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const initValues = idUpdate
    ? {
        record,
      }
    : {
        code: "",
        name: "",
        // excutionTagIds: undefined,
        appliedTo: "barn",
        timeUnitId: "",
        value: "",
        frequencyId: "",
        note: "",
      };

  useEffect(() => {
    form.setFieldsValue(initValues);
    if (!idUpdate) {
      setAddDatas([]);
    }
  }, [idUpdate]);

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div
        className="ant-modal-body"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Row style={{ width: "30%" }}>
          <Col className="gutter-row" span={24}>
            <Title level={5} style={{ paddingBottom: "12px" }}>
              Thông tin cơ bản
            </Title>
            <Form.Item
              required
              label={"Mã quy trình"}
              name={"code"}
              rules={leftYupSync}
            >
              <Input
                disabled={!!idUpdate}
                placeholder="Nhập vào mã quy trình"
              />
            </Form.Item>

            <Form.Item
              required
              label={"Tên quy trình"}
              name={"name"}
              rules={leftYupSync}
            >
              <Input placeholder="Nhập tên quy trình" />
            </Form.Item>

            <Form.Item
              label={"Trạng thái"}
              name={"appliedTo"}
              rules={leftYupSync}
              required
            >
              <Radio.Group>
                <Radio value={"barn"}>Chuồng</Radio>
                <Radio value={"pig"}>Lợn</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "70%" }}>
          <Col className="gutter-row" span={24} style={{ paddingLeft: "17px" }}>
            <Title level={5} style={{ paddingBottom: "12px" }}>
              Thông tin chi tiết
            </Title>

            <Table
              columns={columns}
              rowKey={"id"}
              dataSource={addDatas}
              pagination={false}
              scroll={{ y: 110 }}
            />
            <Row
              style={{
                paddingTop: 20,
                borderWidth: 2,
                borderColor: "red",
                border: 1,
              }}
            >
              <Col className="gutter-row" span={24}>
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  form={formRight}
                  onFinish={() => {
                    handleCreateDetailProcess();
                  }}
                  autoComplete="off"
                >
                  <Form.Item
                    style={{ width: "20%", marginLeft: 15, marginRight: 15 }}
                    required
                    label={""}
                    name={"timeUnitId"}
                    rules={rightYupSync}
                  >
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Chọn đơn vị"
                      onChange={(id: any) => {
                        formRight.setFieldValue("timeUnitId", id);
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={donVi.map((item: any) => {
                        return {
                          label: _.get(item, "name", ""),
                          value: item.name,
                          ...item,
                        };
                      })}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: "20%", marginRight: 15 }}
                    required
                    name={"value"}
                    rules={rightYupSync}
                  >
                    <Input placeholder="Nhập giá trị" />
                  </Form.Item>

                  <Form.Item
                    style={{ width: "20%", marginRight: 15 }}
                    required
                    name={"frequencyId"}
                    rules={rightYupSync}
                  >
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Chọn tần suất"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      onChange={(value: any, label: any) => {
                        formRight.setFieldValue("frequencyId", value);
                      }}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={tanSuat.map((item: any) => {
                        return {
                          label: _.get(item, "name", ""),
                          value: item.name,
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: "35%", marginRight: 15 }}
                    required
                    label={""}
                    name={"note"}
                    rules={rightYupSync}
                  >
                    <Input placeholder="Nhập nội dung" />
                  </Form.Item>

                  <Form.Item style={{ width: "5%", marginLeft: 15 }}>
                    <Button
                      onClick={() => {
                        formRight.submit();
                      }}
                    >
                      {React.createElement(PlusSquareOutlined)}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            closeModal(dispatch, setModal);
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            form.submit();
          }}
          type="primary"
        >
          {idUpdate ? "Lưu lại" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUProcess;
