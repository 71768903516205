/**
 * /auth/login
 * Màn hình đăng nhập
 */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import logo from "assets/img/login-pages/logo.png";
import { GetAccountSuccess, PayloadType, FormAccountType } from "type";
import { useDispatch } from "react-redux";
import { accountLogin } from "features/accountSlice";
import { useHistory } from "react-router";
import * as yup from "yup";
import moment from "moment";
import _ from "lodash";
const BackGroundLoginPage = require("assets/img/login-pages/bg-login.jpg");
const { Text } = Typography;
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const ResetPassword = () => {
  const [isLoadingLogin, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  async function checkLogin() {
    try {
      const accountInfo = await localStorage.getItem("accountInfo");
      const tokens = await localStorage.getItem("tokens");
      if (!!accountInfo && !!tokens) {
        const expires = JSON.parse(tokens).access.expires;
        if (moment(new Date().toISOString()).isBefore(expires)) {
          history.push("/admin/list");
          return;
        }
        localStorage.clear();
      }
    } catch (error) {}
    setIsLogin(false);
  }
  useEffect(() => {
    checkLogin();
  }, []);
  const loginSchema = yup.object().shape({
    phone: yup.string().required("Vui lòng nhập vào số điện thoại!"),
  });
  const yupSync = {
    async validator({ field }: any, value: any) {
      await loginSchema.validateSyncAt(field, { [field]: value });
    },
  };

  function handleLogin(values: FormAccountType) {
    setIsLoading(true);
    const payload: PayloadType = {
      body: {
        phone: _.get(values, "phone", ""),
      },
      callback: {
        success(values: GetAccountSuccess) {
          setIsLoading(false);
          localStorage.setItem(
            "accountInfo",
            JSON.stringify(_.get(values, "user", {}))
          );
          localStorage.setItem(
            "tokens",
            JSON.stringify(_.get(values, "tokens", {}))
          );
          history.push("/admin/list");
        },
        failed() {
          notification.error({
            message: "Thông tin tài khoản hoặc mật khẩu không chính xác!",
          });
          setIsLoading(false);
        },
      },
    };
    dispatch(accountLogin(payload));
  }
  if (!isLogin)
    return (
      <div
        className="bgLogin"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#469692",
          // backgroundImage: `url(${BackGroundLoginPage})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "100% 100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row>
            <Col
              span={24}
              style={{
                padding: " 0px 31px",
                background: "rgba(0, 0, 0, 0.20)",
              }}
            >
              <Space direction="vertical" align="center" size={"small"}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: 446,
                    position: "relative",
                    top: "45px",
                  }}
                />

                <Card
                  size="default"
                  style={{
                    width: 500,
                    paddingLeft: 12,
                    paddingRight: 12,
                    // paddingTop: 0,
                    borderRadius: 24,
                    marginTop: -20,
                  }}
                >
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true, size: "large" }}
                    size={"large"}
                    onFinish={(values) => {
                      handleLogin(values);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 16,
                      }}
                    >
                      <Space
                        align="center"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "#057880",
                          }}
                        >
                          QUÊN MẬT KHẨU
                        </Text>
                        <Text
                          type="secondary"
                          style={{ marginTop: 0, fontSize: "16px" }}
                        >
                          Vui lòng liên hệ với chúng tôi qua số hotline:{" "}
                          <b style={{ color: "red" }}>0339 188 182.</b> để chúng
                          tôi hỗ trợ bạn khi quên mật khẩu đăng nhập.
                        </Text>
                      </Space>
                    </div>

                    <Form.Item>
                      <Space
                        direction="horizontal"
                        style={{ display: "block" }}
                      >
                        <a
                          className="login-form-forgot"
                          style={{
                            display: "block",
                            textAlign: "center",
                            fontSize: 15,
                            fontStyle: "italic",
                            fontWeight: "400",
                            textDecoration: "underline",
                            margin: "auto",
                          }}
                          onClick={() => {
                            history.push("/auth/login");
                          }}
                        >
                          Quay lại đăng nhập
                        </a>
                      </Space>
                    </Form.Item>
                  </Form>
                </Card>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    );
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin tip="Đang tải" size="large">
          <div className="content" />
        </Spin>
      </Space>
    </div>
  );
};

export default ResetPassword;
