/*
 * Import Plugins
 */
import { createSlice } from "@reduxjs/toolkit";

/*
 * Import components or datatype
 */

import { DistrictType } from "type";

/*
 * Declare type of data
 */
interface AdminDataState {
  districts: DistrictType[]; //
  district: DistrictType | null;
  queryDistrict: any; //
  isLoading: boolean;
}

/*
 * Declare variables
 */

const initialState: AdminDataState = {
  districts: [],
  district: null,
  isLoading: false,
  queryDistrict: {
    page: 1,
    limit: 10,
  },
};

/**
 *  Slice manager district
 **/
export const districtSlice: any = createSlice({
  name: "districtSlice",
  initialState: initialState,
  reducers: {
    getDistricts: (state, action) => {
      state.isLoading = true;
    },
    getDistrictsSuccess: (state, action) => {
      state.district = action.payload;
      state.isLoading = false;
    },

    getDistrict: (state, action) => {
      state.isLoading = true;
    },
  },
});
export const { getDistricts, getDistrictsSuccess, getDistrict } =
  districtSlice.actions;
export default districtSlice.reducer;
