import { getParametersSuccess } from "features/parameterSlice";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, PUT } from "services/ServiceBase";
import ServiceURL from "services/ServiceURL";
import { ActionType } from "type";
import _ from "lodash";

export function* getParameters(data: ActionType): any {
  const { callback, body, query, skipUpdateReducer } = data.payload;
  const url = `${ServiceURL.actionGroups}/${ServiceURL.events}?${query ?? ""}`;
  try {
    const res = yield call(GET, url, body);
    if (!!res?.code && !!res?.message) {
      callback.failed(res?.message);
    } else {
      if (!skipUpdateReducer)
        yield put(getParametersSuccess(_.get(res, "data", {})));
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* createParameters(data: ActionType): any {
  const { callback, body } = data.payload;
  const url = ServiceURL.growthParameterss;
  try {
    const res = yield call(POST, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export function* updateParameters(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.actions}/${ServiceURL.events}/${ServiceURL.parametters}/${params}`;
  try {
    const res = yield call(PATCH, url, body);
    if (!!res?.response?.data?.code && !!res?.response?.data?.message) {
      callback.failed(res?.response?.data?.message);
    } else {
      callback.success(_.get(res, "data", {}));
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}
export function* deleteParameters(data: ActionType): any {
  const { callback, body, params } = data.payload;
  const url = `${ServiceURL.growthParameterss}/${params}`;
  try {
    const res = yield call(DELETE, url, body);
    if (res.data?.error?.message) {
      callback.failed(res.data?.error?.message);
    } else {
      callback.success(res);
    }
  } catch (error: any) {
    const err = error?.response?.data?.message || error?.message;
    callback.failed(err);
  }
}

export default function* parameterSaga() {
  yield takeLatest("parameterSlice/getParameters", getParameters);
  yield takeLatest("parameterSlice/createParameters", createParameters);
  yield takeLatest("parameterSlice/updateParameters", updateParameters);
  yield takeLatest("parameterSlice/deleteParameters", deleteParameters);
}
